import axios from 'axios'
import { showToast } from 'vant'

const apiOrigin = import.meta.env.VITE_APP_API_PREFIX as string

const http = axios.create({
  baseURL: apiOrigin,
  withCredentials: false,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' }
})

http.interceptors.request.use(
  (config: any) => {
    if (config.headers) {
      const token = localStorage.getItem('token')
      // token
      if (token) {
        // 仅在请求本站接口时添加 token
        if (config.baseURL?.startsWith(apiOrigin) || config.url?.startsWith(apiOrigin)) {
          config.headers.Authorization = `Token ${token}`
        }
      }

      return config
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response?.data?.detail) {
      showToast({
        message: error.response.data.detail,
        wordBreak: 'normal'
      })
    }
    return Promise.reject(error)
  }
)

export default http
