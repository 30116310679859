<script setup>
import { useAssetsStore } from '@/stores/useAssetsStore'
const assetsStore = useAssetsStore()
</script>

<template>
  <van-tabbar route :fixed="false">
    <van-tabbar-item replace to="/tap">
      <template #icon>
        <svg-icon name="tap" />
      </template>
      Tap
    </van-tabbar-item>
    <van-tabbar-item replace to="/earn">
      <template #icon>
        <svg-icon name="earn" />
      </template>
      Earn
    </van-tabbar-item>
    <van-tabbar-item replace to="/claim" :dot="!assetsStore.hasRead">
      <template #icon>
        <svg-icon name="claim" />
      </template>
      Claim
    </van-tabbar-item>
  </van-tabbar>
</template>

<style lang="scss" scoped>
:deep(.van-tabbar-item--active) {
  .svg-icon {
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.6));
  }
}
</style>
