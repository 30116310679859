import { defineStore } from 'pinia'
import { getPoints, getMsgStatus, setMsgStatus } from '@/apis/index'

export interface Assets {
  budgetPoints: number
  criticalHitTimeout: number
  donatablePoints: number
  donateDifferentFriends: number
  donatePoints: number
  earnPoints: number
  points: number
  remainPower: number
  totalPower: number
  transferablePoints: number
}

export const useAssetsStore = defineStore('assets', () => {
  const assets = ref<Assets>({
    budgetPoints: 0,
    criticalHitTimeout: 0,
    donatablePoints: 0,
    donateDifferentFriends: 0,
    donatePoints: 0,
    earnPoints: 0,
    points: 0,
    remainPower: 0,
    totalPower: 0,
    transferablePoints: 0
  })

  const hasRead = ref(true)

  const getAssets = async () => {
    try {
      const res: any = await getPoints()
      assets.value = res as Assets
    } catch (error) {
      console.error(error)
    }
  }

  const getMsgReadStatus = async () => {
    try {
      const { read }: any = await getMsgStatus()
      hasRead.value = read
    } catch (error) {
      console.error(error)
    }
  }

  const setMsgHasRead = async () => {
    try {
      const res: any = await setMsgStatus()
      console.log(res)
      hasRead.value = true
    } catch (error) {
      console.error(error)
    }
  }

  return {
    assets,
    hasRead,
    getAssets,
    getMsgReadStatus,
    setMsgHasRead
  }
})
