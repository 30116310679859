/*
 * @Author: jingren renjing_123455@163.com
 * @Date: 2024-09-10 14:58:59
 * @LastEditors: jingren renjing_123455@163.com
 * @LastEditTime: 2024-09-10 15:05:57
 * @FilePath: /rinku/apps/muyu/src/stores/useRouteCacheStore.ts
 */
import { defineStore } from 'pinia'
import type { RouteRecordName, RouteLocationNormalized } from 'vue-router'

const useRouteCacheStore = defineStore('route-cache', () => {
  const routeCaches = ref<RouteRecordName[]>([])

  const addRoute = (route: RouteLocationNormalized) => {
    if (routeCaches.value.includes(route.name)) return

    routeCaches.value.push(route.name)
  }

  const delRoute = (route: RouteLocationNormalized) => {
    if (!routeCaches.value.includes(route.name)) return

    const idx = routeCaches.value.indexOf(route.name)
    routeCaches.value.splice(idx + 1, routeCaches.value.length - 1 - idx)
  }

  return {
    routeCaches,
    addRoute,
    delRoute
  }
})

export default useRouteCacheStore
