/*
 * @Author: jingren renjing_123455@163.com
 * @Date: 2024-09-10 13:50:34
 * @LastEditors: jingren renjing_123455@163.com
 * @LastEditTime: 2024-09-10 15:06:39
 * @FilePath: /rinku/apps/muyu/src/main.ts
 */
import '@/assets/scss/main.css'
import 'vant/es/toast/style'
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import WebApp from '@twa-dev/sdk'
import 'virtual:svg-icons-register'
import { Locale } from 'vant'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US'

Locale.use('en-US', enUS)

declare global {
  interface Window {
    WebApp?: typeof WebApp // 使用可选属性以允许其为 undefined 或存在
  }
}

// 安全地设置 window.WebApp
if (typeof window !== 'undefined') {
  window.WebApp = WebApp
}

WebApp.ready()
WebApp.expand()
WebApp.isVerticalSwipesEnabled && WebApp.disableVerticalSwipes()
WebApp.setHeaderColor('#0F1012')
WebApp.setBackgroundColor('#0F1012')

WebApp.onEvent('themeChanged', function () {
  document.body.setAttribute('style', '--bg-color:' + WebApp.backgroundColor)
})

const token = new URLSearchParams(location.search).get('token')

if (token) {
  localStorage.setItem('token', token)
}

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(router)
app.mount('#app')

// 阻止移动端缩放
if (window.innerWidth < 768) {
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

//ios
document.oncontextmenu = function (e) {
  e.preventDefault()
}
document.onselectstart = function (e) {
  e.preventDefault()
}
//安卓
document.addEventListener('contextmenu', function (e) {
  e.preventDefault()
})
document.ontouchend = function () {
  // throw new Error('NO ERRPR:禁止长按弹出')
}
