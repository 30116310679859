import http from './http'

/**
 * 获取初始化数据
 * @returns
 */
export const getConfig = () =>
  http({
    url: '/muyu/config/',
    method: 'GET'
  })

/**
 * 获取初始化数据
 * @returns
 */
export const getPoints = () =>
  http({
    url: '/muyu/points/',
    method: 'GET'
  })

/**
 * 敲击木鱼
 * @returns
 */
export const clickReward = () =>
  http({
    url: '/muyu/click/',
    method: 'POST'
  })

/**
 * 敲击木鱼
 * @returns
 */
export const submitResult = (data: any) =>
  http({
    url: '/muyu/result/',
    method: 'POST',
    data
  })

export const transfer = (data: any) =>
  http({
    url: '/muyu/transfer/',
    method: 'POST',
    data
  })

export const searchUsername = (data: any) =>
  http({
    url: '/tg_bot/search_username/',
    method: 'POST',
    data
  })

export const donate = (data: any) =>
  http({
    url: '/muyu/donate/',
    method: 'POST',
    data
  })

export const getTasks = () =>
  http({
    url: '/muyu/tasks/',
    method: 'GET'
  })

export const rewardTask = (data: any) =>
  http({
    url: '/muyu/reward/',
    method: 'POST',
    data
  })

/**
 * 获取排行榜数据
 * @returns
 */
export const getRankingList = (params: { page: number; size: number }) =>
  http({
    url: '/muyu/ranking/',
    method: 'GET',
    params
  })

/**
 * 获取我的收益列表
 * @returns
 */
export const getMyEarns = (params: { page: number; size: number; ordering: string }) =>
  http({
    url: '/muyu/my_earns/',
    method: 'GET',
    params
  })

/**
 * 获取消息是否已读
 * @returns
 */
export const getMsgStatus = () =>
  http({
    url: '/muyu/message_read/',
    method: 'GET'
  })

/**
 * 设置消息已读
 * @returns
 */
export const setMsgStatus = () =>
  http({
    url: '/muyu/message_read/',
    method: 'POST'
  })

export const getInviteLink = () =>
  http({
    url: '/tg_bot/invite_link/',
    method: 'GET'
  })
