import { createRouter, createWebHistory } from 'vue-router'
import useRouteCacheStore from '@/stores/useRouteCacheStore'

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/tap'
    },
    {
      path: '/tap',
      name: 'Tap',
      meta: {
        layout: true
      },
      component: () => import('@/views/Tap.vue')
    },
    {
      path: '/earn',
      children: [
        {
          path: '',
          name: 'Earn',
          meta: {
            layout: true
          },
          component: () => import('@/views/Earn.vue')
        },
        {
          path: 'transfer',
          name: 'Transfer',
          component: () => import('@/views/Transfer.vue')
        },
        {
          path: 'donate_search',
          name: 'DonateSearch',
          component: () => import('@/views/DonateSearch.vue')
        },
        {
          path: 'donate/:username',
          name: 'Donate',
          component: () => import('@/views/Donate.vue')
        }
      ]
    },
    {
      path: '/claim',
      children: [
        {
          path: '',
          name: 'Claim',
          meta: {
            layout: true
          },
          component: () => import('@/views/Claim.vue')
        },
        {
          path: 'record',
          name: 'Record',
          component: () => import('@/views/DonateRecord.vue')
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  const routeCacheStore = useRouteCacheStore()

  if (to.meta.layout && !from.meta.layout) {
    routeCacheStore.delRoute(from)
  } else {
    routeCacheStore.addRoute(from)
  }

  console.log(to)

  const { path, token, username } = to.query
  if (token && username && path === 'Donate') {
    next({
      name: 'Donate',
      params: {
        username: username as string
      }
    })
  }
  next()
})

export default router
