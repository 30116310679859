<script setup>
import useRouteCacheStore from '@/stores/useRouteCacheStore'
import TabBar from './TabBar.vue'
import WebApp from '@twa-dev/sdk'

const route = useRoute()
const router = useRouter()

const showLayout = computed(() => {
  return route.meta.layout
})

watch(showLayout, (newVal) => {
  // 二级目录
  if (!newVal) {
    WebApp.BackButton.show()
    WebApp.BackButton.onClick(() => {
      router.back()
    })
  } else {
    WebApp.BackButton.hide()
    WebApp.BackButton.offClick()
  }
})

const keepAliveRouteNames = computed(() => {
  const routeCaches = useRouteCacheStore().routeCaches
  return routeCaches
})
</script>

<template>
  <div class="layout">
    <div class="router-wrapper">
      <router-view v-slot="{ Component }">
        <keep-alive :include="keepAliveRouteNames">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
    <TabBar v-if="showLayout"></TabBar>
  </div>
</template>

<style lang="less" scoped>
.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--tg-viewport-stable-height, 100vh);
  overflow: hidden;
  box-sizing: border-box;

  .router-wrapper {
    flex: 1;
    overflow: scroll;
  }

  .van-tabbar {
    position: static;
    flex-shrink: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
</style>
